<template>
  <div class="horizontal-layout horizontal-menu navbar-floating footer-static">
    <nav class="navbar header-navbar align-items-center navbar-brand-center navbar-light navbar-expand">
      <div class="navbar-container text-center">
        <span class="brand-logo">
          <b-img
            src="@/assets/images/logo/logo-datasoft.png"
            width="100"
            alt="Logo"
          />
        </span>
      </div>
    </nav>
    <div class="bg-primary py-1">
      <div class="text-center">
        <h2 class="text-white mb-0">
          H-1B Intake Form
        </h2>
      </div>
    </div>
    <div class="intake-form mt-5">
      <div class="container">
        <b-card no-body>
          <b-card-body>
            <div class="bg-primary pl-2 py-1">
              <h4 class="text-white mb-0">
                Personal Information
              </h4>
            </div>
            <validation-observer ref="simpleRules">
              <b-form class="mt-2 px-2">
                <b-row>
                  <b-col cols="6">
                    <b-form-group>
                      <label>First Name</label>
                      <span class="text-danger"> *</span>
                      <validation-provider
                        #default="{ errors }"
                        name="First Name"
                        rules="required"
                      >
                        <b-form-input
                          v-model="addIntakeForm.first_name"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group
                      label="Middel Name"
                    >
                      <b-form-input
                        v-model="addIntakeForm.middle_name"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group>
                      <label>Last Name</label>
                      <span class="text-danger"> *</span>
                      <validation-provider
                        #default="{ errors }"
                        name="Last Name"
                        rules="required"
                      >
                        <b-form-input
                          v-model="addIntakeForm.last_name"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group>
                      <label>Email Address</label>
                      <span class="text-danger"> *</span>
                      <validation-provider
                        #default="{ errors }"
                        name="Email Address"
                        rules="required|email"
                      >
                        <b-form-input
                          v-model="addIntakeForm.email_address"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group>
                      <label>Phone Number</label>
                      <span class="text-danger"> *</span>
                      <validation-provider
                        #default="{ errors }"
                        name="Phone Number"
                        rules="required|integer|digits:10"
                      >
                        <b-form-input
                          v-model="addIntakeForm.phone_number"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group
                      label="H-1B/I-94 Expiration Date"
                    >
                      <b-input-group>
                        <flat-pickr
                          v-model="expiration_date"
                          class="form-control"
                          :config="$store.state.config"
                          placeholder="MM-DD-YYYY"
                          :clear-button="true"
                        />
                        <b-input-group-append>
                          <b-button
                            class="px-1 outline-grey"
                            variant="outline-secondary"
                            @click="removeVisaExpairyDate"
                          >
                            <feather-icon icon="XIcon" />
                          </b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="6">
                    <b-form-group>
                      <label>Education (Field of Study)</label>
                      <span class="text-danger"> *</span>
                      <validation-provider
                        #default="{ errors }"
                        name="Education (Field of Study)"
                        rules="required"
                      >
                        <b-form-input
                          v-model="addIntakeForm.education"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group>
                      <label>Bachelor's</label>
                      <span class="text-danger"> *</span>
                      <validation-provider
                        #default="{ errors }"
                        name="Bachelor's"
                        rules="required"
                      >
                        <b-form-input
                          v-model="addIntakeForm.bachelors"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group>
                      <label>Master's (if applicable)</label>
                      <b-form-input
                        v-model="addIntakeForm.masters"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="6">
                    <b-form-group>
                      <label>Is the foreign national currently employed with your company?</label>
                      <div class="demo-inline-spacing">
                        <b-form-radio
                          v-model="addIntakeForm.is_currently_employeed"
                          name="currently_employeed_yes"
                          value="true"
                        >
                          Yes
                        </b-form-radio>
                        <b-form-radio
                          v-model="addIntakeForm.is_currently_employeed"
                          name="currently_employeed_no"
                          value="false"
                        >
                          No
                        </b-form-radio>
                      </div>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group>
                      <label>If yes, is the work location the same as the previous petition?</label>
                      <div class="demo-inline-spacing">
                        <b-form-radio
                          v-model="addIntakeForm.is_work_location_previous_location"
                          name="work_location_previous_location_yes"
                          value="true"
                        >
                          Yes
                        </b-form-radio>
                        <b-form-radio
                          v-model="addIntakeForm.is_work_location_previous_location"
                          name="work_location_previous_location_no"
                          value="false"
                        >
                          No
                        </b-form-radio>
                      </div>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group>
                      <label>Proposed/Current Salary</label>
                      <b-input-group
                        prepend="$"
                      >
                        <money
                          v-model="addIntakeForm.salary"
                          :min="0"
                          :step="0.5"
                          v-bind="money"
                          class="form-control"
                        />
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group>
                      <label>Employee’s Job Title</label>
                      <span class="text-danger"> *</span>
                      <validation-provider
                        #default="{ errors }"
                        name="Employee’s Job Title"
                        rules="required"
                      >
                        <b-form-input
                          v-model="addIntakeForm.job_title"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group>
                      <label>In-house at employer’s own location</label>
                      <div class="demo-inline-spacing">
                        <b-form-radio
                          v-model="addIntakeForm.is_employer_location"
                          name="employer_location_yes"
                          value="true"
                        >
                          Yes
                        </b-form-radio>
                        <b-form-radio
                          v-model="addIntakeForm.is_employer_location"
                          name="employer_location_no"
                          value="false"
                        >
                          No
                        </b-form-radio>
                      </div>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group>
                      <label>Client location – please provide the following</label>
                      <b-form-input
                        v-model="addIntakeForm.client_location"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group>
                      <label>End-user’s name, full address with street, city, state, and zip code</label>
                      <b-form-input
                        v-model="addIntakeForm.end_user_details"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group>
                      <label>Middle vendor (if applicable)</label>
                      <b-form-input
                        v-model="addIntakeForm.middle_vendor"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group>
                      <label>Detailed description of job duties/responsibilities (can be attached as a separate document) <span class="text-danger"> *</span></label>
                      <validation-provider
                        #default="{ errors }"
                        name="Description"
                        rules="required"
                      >
                        <b-form-textarea
                          v-model="addIntakeForm.description"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group>
                      <label>Do you have a spouse and/or child currently in the US who need H4?</label>
                      <div class="demo-inline-spacing">
                        <b-form-radio
                          v-model="addIntakeForm.need_h4"
                          name="need_h4_yes"
                          value="true"
                        >
                          Yes
                        </b-form-radio>
                        <b-form-radio
                          v-model="addIntakeForm.need_h4"
                          name="need_h4_no"
                          value="false"
                        >
                          No
                        </b-form-radio>
                      </div>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group>
                      <label>Do you have a green card petition in process?</label>
                      <div class="demo-inline-spacing">
                        <b-form-radio
                          v-model="addIntakeForm.green_card_petitions"
                          name="green_card_petitions_yes"
                          value="true"
                        >
                          Yes
                        </b-form-radio>
                        <b-form-radio
                          v-model="addIntakeForm.green_card_petitions"
                          name="green_card_petitions_no"
                          value="false"
                        >
                          No
                        </b-form-radio>
                      </div>
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group>
                      <label>Linkedin Url <span class="text-danger"> *</span></label>
                      <validation-provider
                        #default="{ errors }"
                        name="Linkedin"
                        rules="required"
                      >
                        <b-form-input
                          v-model="addIntakeForm.linkedin_url"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <hr>

                <div class="d-flex mt-2">
                  <h4 class="mb-0">
                    Reference
                  </h4>
                </div>
                <div
                  v-for="(reference, index) in addIntakeForm.referrers"
                  :key="index"
                  class="d-flex"
                >
                  <div class="left w-100">
                    <b-row class="mt-1">
                      <b-col cols="6">
                        <b-form-group>
                          <label>First Name</label>
                          <validation-provider
                            #default="{ errors }"
                            :name="'First Name' + index"
                            :rules="isRequired(index) ? 'required' : ''"
                          >
                            <b-form-input
                              v-model="reference.first_name"
                              :state="errors.length > 0 ? false:null"
                            />
                            <small class="text-danger">{{ errors[0] ? errors[0].replace('First Name' + index, 'First Name') : '' }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="6">
                        <b-form-group
                          label="Last Name"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="'Last Name' + index"
                            :rules="isRequired(index) ? 'required' : ''"
                          >
                            <b-form-input
                              v-model="reference.last_name"
                              :state="errors.length > 0 ? false:null"
                            />
                            <small class="text-danger">{{ errors[0] ? errors[0].replace('Last Name' + index, 'Last Name') : '' }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="6">
                        <b-form-group
                          label="Company"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="'Comapany' + index"
                            :rules="isRequired(index) ? 'required' : ''"
                          >
                            <b-form-input
                              v-model="reference.company"
                              :state="errors.length > 0 ? false:null"
                            />
                            <small class="text-danger">{{ errors[0] ? errors[0].replace('Comapany' + index, 'Comapany') : '' }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="6">
                        <b-form-group
                          label="Type"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="'Type' + index"
                            :rules="isRequired(index) ? 'required' : ''"
                          >
                            <v-select
                              v-model="reference.type_id"
                              :clearable="true"
                              placeholder="Select"
                              :options="types"
                              :reduce="item => item.id"
                            />
                            <small class="text-danger">{{ errors[0] ? errors[0].replace('Type' + index, 'Type') : '' }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="6">
                        <b-form-group>
                          <label>Official Email</label>
                          <validation-provider
                            #default="{ errors }"
                            :name="'Email' + index"
                            :rules="isRequired(index) ? 'required|email' : ''"
                          >
                            <b-form-input
                              v-model="reference.email"
                              :state="errors.length > 0 ? false:null"
                            />
                            <small class="text-danger">{{ errors[0] ? errors[0].replace('Email' + index, 'Email') : '' }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="6">
                        <b-form-group>
                          <label>Phone Number</label>
                          <validation-provider
                            #default="{ errors }"
                            :name="'Phone Number' + index"
                            :rules="isRequired(index) ? 'required|integer|digits:10' : ''"
                          >
                            <b-form-input
                              v-model="reference.phone"
                              :state="errors.length > 0 ? false:null"
                            />
                            <small class="text-danger">{{ errors[0] ? errors[0].replace('Phone Number' + index, 'Phone Number') : '' }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </div>
                  <div class="right mt-1 ml-1">
                    <b-button
                      v-show="index === 0"
                      variant="outline-primary"
                      class="btn-icon rounded-circle"
                      @click="addReference"
                    >
                      <feather-icon icon="PlusIcon" />
                    </b-button>
                    <b-button
                      v-show="index !== 0"
                      variant="outline-danger"
                      class="btn-icon rounded-circle"
                      @click="removeReference(index)"
                    >
                      <feather-icon icon="XIcon" />
                    </b-button>
                  </div>
                </div>
                <hr>

                <div class="d-flex mt-2">
                  <h4 class="mb-0 mr-1">
                    Documents
                  </h4>
                  <b-badge variant="light-warning">
                    <feather-icon
                      icon="InfoIcon"
                      class="mr-25"
                    />
                    <span>Resume Required</span>
                  </b-badge>
                </div>
                <b-row class="mt-1">
                  <b-col cols="6">
                    <b-form-group>
                      <label>Type</label>
                      <v-select
                        v-model="documentNameId"
                        :clearable="true"
                        placeholder="Select"
                        :options="documentNames"
                        :reduce="item => item.id"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group>
                      <label>Attachment</label>
                      <div class="d-flex">
                        <input
                          ref="myFileInput"
                          type="file"
                          placeholder="Choose a file or drop it here..."
                          class="form-control text-left"
                          @change="attahamentFileSelected"
                        >
                        <b-button
                          variant="outline-primary"
                          class="ml-1 px-1"
                          @click="addFile"
                        >
                          Upload
                        </b-button>
                      </div>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-button-group
                      v-for="(option, index) in addIntakeForm.documents"
                      :key="option.id"
                      :value="option.id"
                      class="mr-1"
                    >
                      <b-badge
                        :href="option.attachment_link"
                        :title="option.name"
                        target="option.attachment_link"
                        variant="light-primary"
                        class="rounded-right-0 line-height-inherit"
                      >
                        <span>{{ option.name }}</span>
                      </b-badge>
                      <b-button
                        variant="danger"
                        class="px-50 py-25"
                        @click="removeFile(option, index)"
                      >
                        <feather-icon icon="XIcon" />
                      </b-button>
                    </b-button-group>
                  </b-col>
                </b-row>
                <hr>

                <b-row>
                  <b-col
                    cols="12"
                    class="text-right"
                  >
                    <b-button
                      variant="primary"
                      type="button"
                      @click="submitForm"
                    >
                      Submit Form
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-card-body>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BCardBody, BForm, BRow, BCol, BFormGroup, BInputGroup, BInputGroupAppend, BFormInput, BFormTextarea, BFormRadio, BImg, BBadge, BButtonGroup, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {
  required,
} from '@validations'
import flatPickr from 'vue-flatpickr-component'
import H1B from '@/js/h1b'
import Constants from '@/js/constant'

export default {
  components: {
    BCard,
    BCardBody,
    BForm,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BFormTextarea,
    BFormRadio,
    BImg,
    BBadge,
    BButtonGroup,
    BButton,
    flatPickr,
    vSelect,
  },
  data() {
    return {
      required,
      addIntakeForm: {
        first_name: '',
        middle_name: '',
        last_name: '',
        email_address: '',
        phone_number: '',
        expiration_date: null,
        education: '',
        bachelors: '',
        masters: '',
        is_currently_employeed: false,
        is_work_location_previous_location: false,
        salary: '',
        job_title: '',
        is_employer_location: false,
        client_location: '',
        end_user_details: '',
        middle_vendor: '',
        description: '',
        need_h4: false,
        green_card_petitions: false,
        linkedin_url: '',
        referrers: [],
        documents: [],
      },
      types: [],
      money: {
        decimal: '.',
        thousands: ',',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false,
      },
      expiration_date: null,
      documentNameId: null,
      selectedFile: null,
      documentNames: [],
    }
  },
  created() {
    H1B.getDocumentNameFullList(this)
    H1B.getReferrerTypes(this)
    H1B.verify(this, this.$route.params.token)
    this.addReference()
  },
  methods: {
    submitForm() {
      this.$refs.simpleRules.validate().then(result => {
        if (result) {
          this.addIntakeForm.referrers = this.addIntakeForm.referrers.filter(referrer => this.isRequiredByReferrer(referrer))
          if (this.documentNameId !== null && this.selectedFile === null) {
            this.$swal({
              title: 'Upload',
              text: 'Please choose file',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            return
          }
          if (this.documentNameId === null && this.selectedFile !== null) {
            this.$swal({
              title: 'Upload',
              text: 'Please select document type',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            return
          }
          if (this.documentNameId !== null && this.selectedFile !== null) {
            this.addIntakeForm.documents.push({
              document_name_id: this.documentNameId,
              attachment_link: this.url,
              document: this.selectedFile,
              file_name: this.documentName,
              name: this.documentName,
            })
            this.selectedFile = null
            this.url = null
            this.documentNameId = null
            this.$refs.myFileInput.value = ''
          }
          let documentExist = false
          this.addIntakeForm.documents.forEach(elem => {
            const items = this.documentNames.filter(data => data.label.toLowerCase() === 'resume' && data.id === elem.document_name_id)
            if (items.length !== 0) {
              documentExist = true
            }
          })
          if (!documentExist) {
            this.$swal({
              title: 'Info!',
              text: 'Please upload resume!',
              icon: 'info',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            return
          }
          this.showLoading = true
          if (this.expiration_date !== null) {
            this.addIntakeForm.expiration_date = Constants.getEprmMonthDateFromString(this.expiration_date)
          } else {
            this.addIntakeForm.expiration_date = null
          }
          H1B.addH1bUserDetails(this, this.$route.params.token, this.addIntakeForm)
        }
      })
    },
    isRequired(position) {
      return this.isRequiredByReferrer(this.addIntakeForm.referrers[position])
    },
    isRequiredByReferrer(referrer) {
      return referrer.first_name !== '' || referrer.last_name !== '' || referrer.company !== '' || referrer.type_id !== null || referrer.email !== '' || referrer.phone !== ''
    },
    attahamentFileSelected(obj) {
      const dis = this
      const fileReader = new FileReader()
      fileReader.onload = fileLoadEvent => {
        dis.selectedFile = fileLoadEvent.target.result
      }
      fileReader.readAsDataURL(obj.target.files[0])
      this.url = URL.createObjectURL(obj.target.files[0])
      this.documentName = obj.target.files[0].name
    },
    removeFile(obj, position) {
      const dis = this
      this.$swal({
        title: 'Are you sure?',
        text: `you wan't to delete this ${obj.name}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          dis.addIntakeForm.documents.splice(position, 1)
        }
      })
    },
    addFile() {
      if (this.documentNameId !== null && this.selectedFile === null) {
        this.$swal({
          title: 'Error!',
          text: ' Please choose file',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        return
      }
      if (this.documentNameId === null && this.selectedFile !== null) {
        this.$swal({
          title: 'Error!',
          text: ' Please select document type',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        return
      }
      if (this.documentNameId !== null && this.selectedFile !== null) {
        this.addIntakeForm.documents.push({
          document_name_id: this.documentNameId, attachment_link: this.url, document: this.selectedFile, file_name: this.documentName, name: this.documentName,
        })
        this.selectedFile = null
        this.url = null
        this.documentNameId = null
        this.$refs.myFileInput.value = ''
      }
    },
    removeVisaExpairyDate() {
      this.expiration_date = null
    },
    setDocumentNames(documentNames) {
      this.documentNames = documentNames
    },
    setReferrerTypes(types) {
      this.types = types
    },
    setValid(data) {
      this.$refs.showLoading = false
      this.addIntakeForm.email_address = data.email_address
    },
    setNotValid(message) {
      this.$refs.showLoading = false
      window.location.hash = '/login'
      this.$swal({
        title: 'Error!',
        text: message,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    addReference() {
      const reference = {
        first_name: '',
        last_name: '',
        company: '',
        type_id: null,
        email: '',
        phone: '',
      }
      this.addIntakeForm.referrers.push(reference)
    },
    removeReference(index) {
      this.addIntakeForm.referrers.splice(index, 1)
    },
    h1bUserDetailsAdded(message) {
      this.$refs.showLoading = false
      window.location.hash = '/login'
      this.$swal({
        title: 'Success!',
        text: message,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    h1bUserDetailsNotAdded(message) {
      this.$refs.showLoading = false
      this.$swal({
        title: 'Error!',
        text: message,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
